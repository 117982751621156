// src/components/buy_page/buyview/buy_edit/BuyBillEditForm.js

import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Optional: Using Bootstrap for styling
import SupplierTable from '../../../main_sql/SupplierTable'; // Adjust path as needed

function BuyBillEditForm({ initialData, onSave, onClose }) {
  const [formData, setFormData] = useState({
    buyDate: '',
    supplier: '',
    supplierId: '', // Add supplierId to formData
    buyComment: '',
    billDiscount: '',
    buybillno: '',
  });

  const [showSupplierModal, setShowSupplierModal] = useState(false); // State for supplier modal visibility

  useEffect(() => {
    if (initialData) {
      setFormData({
        buyDate: initialData.buybilldate || '',
        supplier: initialData.suppname || '',  // Initialize with supplier name
        supplierId: initialData.buysupp || '', // Initialize with supplier ID
        buyComment: initialData.buybillcomment || '',
        billDiscount: initialData.buybilldisc || '',
        buybillno: initialData.buybillno || '',
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSupplierSelect = (supplier) => {
    // Update both supplier name and supplier ID
    setFormData((prevData) => ({
      ...prevData,
      supplier: supplier.suppname,  // Set the selected supplier's name
      supplierId: supplier.suppid,  // Set the selected supplier's ID
    }));
    setShowSupplierModal(false); // Close the modal
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure that if billDiscount is null or empty, it defaults to 0
    const updatedFormData = {
      ...formData,
      billDiscount: formData.billDiscount ? formData.billDiscount : 0,
    };
    console.log(updatedFormData);
    if (onSave) {
      // Ensure that formData is passed back to the parent component with the updated supplierId
      onSave(updatedFormData);
    }
  };

  return (
    <>
      <Modal show={true} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Buy Bill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="buyDate" className="form-label">
                วันที่ซื้อ
              </label>
              <input
                type="date"
                id="buyDate"
                name="buyDate"
                value={formData.buyDate}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="supplier" className="form-label">
                ผู้จำหน่าย
              </label>
              <input
                type="text"
                id="supplier"
                name="supplier"
                value={formData.supplier} // This should now reflect the selected supplier name
                onClick={() => setShowSupplierModal(true)} // Open supplier modal on click
                readOnly // Set read-only to prevent manual editing
                className="form-control"
                placeholder="Click to select a supplier"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="buybillno" className="form-label">
                ผู้จำหน่าย
              </label>
              <input
                type="text"
                id="buybillno"
                name="buybillno"
                value={formData.buybillno} // This should now reflect the selected supplier name
                onChange={handleChange}
                className="form-control"
                placeholder="เลขที่บิล"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="buyComment" className="form-label">
                หมายเหตุ
              </label>
              <textarea
                id="buyComment"
                name="buyComment"
                value={formData.buyComment}
                onChange={handleChange}
                className="form-control"
                placeholder="Enter comment"
                rows={3}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="billDiscount" className="form-label">
                ส่วนลดท้ายบิล
              </label>
              <input
                type="number"
                id="billDiscount"
                name="billDiscount"
                value={formData.billDiscount}
                onChange={handleChange}
                className="form-control"
                placeholder="Enter Bill Discount"
              />
            </div>

            <Button variant="primary" type="submit">
              Save Changes
            </Button>
            <Button variant="secondary" onClick={onClose} className="ms-2">
              Close
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      {/* SupplierTable Modal */}
      <Modal show={showSupplierModal} onHide={() => setShowSupplierModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SupplierTable onSelect={handleSupplierSelect} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BuyBillEditForm;