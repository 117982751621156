// src/utils/apiClient.js
import axios from 'axios';

// Helper functions to retrieve tokens from localStorage
const getApiKey = () => localStorage.getItem('api_key');
const getAccessToken = () => localStorage.getItem('access_token');
const getRefreshToken = () => localStorage.getItem('refresh_token');

// Create an Axios instance with base configurations
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Set your base URL here
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to dynamically add API key and access token
axiosInstance.interceptors.request.use(
  (config) => {
    const apiKey = getApiKey();
    const accessToken = getAccessToken();

    if (apiKey) {
      config.headers['X-API-KEY'] = apiKey;
    }
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Function to refresh the access token using the refresh token
const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/token/refresh/`,
      {
        refresh: refreshToken,
      }
    );

    const { access, refresh } = response.data;
    localStorage.setItem('access_token', access);

    // If your backend rotates refresh tokens, update it
    if (refresh) {
      localStorage.setItem('refresh_token', refresh);
    }

    console.log('Access token successfully refreshed:', access); // Logging token refresh
    return access;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

// Response interceptor to handle 401 errors and refresh token logic
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if 401 error and ensure it hasn't already retried
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();

        // Update headers with the new access token and retry the original request
        axiosInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${newAccessToken}`;
        originalRequest.headers[
          'Authorization'
        ] = `Bearer ${newAccessToken}`;

        return axiosInstance(originalRequest); // Retry request with updated token
      } catch (err) {
        console.error('Token refresh failed; redirecting to login.');
        // Clear tokens and redirect to login
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('api_key');
        window.location.href = '/login/';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;