// src/components/buy_page/BuyBillForm.js

import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import SupplierTable from '../main_sql/SupplierTable'; // Import SupplierTable
import { getToday } from '../../utils/dateFunc';

function BuyBillForm({ onChange }) {
  const [formData, setFormData] = useState({
    buyComment: localStorage.getItem('buyComment') || '',
    buyDate: localStorage.getItem('buyDate') || getToday(),
    supplier: localStorage.getItem('supplier') || '',
    supplierId: localStorage.getItem('supplierId') || '',
    Buybillno: localStorage.getItem('Buybillno') || '',
  });
  const [showSupplierModal, setShowSupplierModal] = useState(false); // State to control the modal visibility

  // Set the default buyDate to today's date on component mount
  useEffect(() => {
    const todayDate = getToday();
    const updatedData = {
      ...formData,
      buyDate: todayDate,
    };
    setFormData(updatedData);
    onChange(updatedData); // Pass the updated data to the parent component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { [name]: value };
    setFormData((prevData) => ({ ...prevData, ...updatedData }));
    localStorage.setItem(name, value); // Save the value to localStorage
    onChange(updatedData); // Pass only the updated field
  };

  // Open the supplier selection modal
  const handleSupplierClick = () => {
    setShowSupplierModal(true);
  };

  // Handle supplier selection from the modal
  const handleSupplierSelect = (supplier) => {
    const updatedData = {
      supplier: supplier.suppname,
      supplierId: supplier.suppid,
    };
    setFormData((prevData) => ({ ...prevData, ...updatedData }));
    localStorage.setItem('supplier', supplier.suppname); // Save supplier name to localStorage
    localStorage.setItem('supplierId', supplier.suppid); // Save supplier ID to localStorage
    onChange(updatedData);
    setShowSupplierModal(false); // Close the modal
  };

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-auto">
          <input
            type="date"
            id="buyDate"
            name="buyDate"
            value={formData.buyDate}
            onChange={handleChange}
            className="form-control"
            placeholder="วันที่ซื้อ"
            required
            style={{ maxWidth: '150px', width: '100%', marginBottom: '10px', height: '40px' }}
          />
        </div>
        <input
          type="text"
          id="supplier"
          name="supplier"
          value={formData.supplier}
          readOnly
          onClick={handleSupplierClick}
          onChange={handleChange}
          className="form-control"
          placeholder="ชื่อผู้จำหน่าย"
          style={{ maxWidth: '150px', width: '100%' , marginBottom: '10px', marginLeft: '0px', height: '40px'  }}
        />
        <input
          type="text"
          id="Buybillno"
          name="Buybillno"
          value={formData.Buybillno}
          readOnly
          onChange={handleChange}
          className="form-control"
          placeholder="เลขที่บิล"
          style={{ maxWidth: '150px', width: '100%' , marginBottom: '10px', marginLeft: '12px', height: '40px'  }}
        />
        <div className="col">
          <input
            type="text"
            id="buyComment"
            name="buyComment"
            value={formData.buyComment}
            onChange={handleChange}
            className="form-control"
            placeholder="หมายเหตุบิล"
            style={{ maxWidth: '200px', width: '100%', height: '40px', textAlign: 'left'}}
          />
        </div>
      </div>
      {/* Supplier selection modal */}
      <Modal show={showSupplierModal} onHide={() => setShowSupplierModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>เลือกผู้จำหน่าย</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SupplierTable onSelect={handleSupplierSelect} /> {/* Pass the handleSupplierSelect function */}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowSupplierModal(false)}>
            ปิด
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BuyBillForm;