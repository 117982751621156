// src/components/buy_page/buyview/BuyBillViewTable.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/apiClient';
import CustomTable from '../../../utils/CustomTable';
import BuyViewFilterForm from './BuyViewFilterForm';
import DoWithLineModal from '../../../utils/DoWithLineModal';
import BuyBillEditForm from './buy_edit/BuyBillEditForm';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../../redux/slices/columnSlice';

function BuyBillViewTable({ 
  onBuyBillSelect, 
  onFilterChange, 
  filters, 
  onDataChange, 
  onClearFilters,
  dataVersion,
}) {
  const [buyBills, setBuyBills] = useState([]);
  const [filteredBuyBills, setFilteredBuyBills] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedBuyBill, setSelectedBuyBill] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'buy'));
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch buy bills
        const billsResponse = await axiosInstance.get('/Buybillv/');
        // Set buy bills
        setBuyBills(billsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [filters, dataVersion]);

  // Apply filters whenever buyBills or filters change
  const applyFilters = (data, filters) => {
    const { dateFrom, dateTo, supplierName, billComment,buybillno, showAll } = filters;

    const filtered = data.filter((bill) => {
      const billDate = new Date(bill.buybilldate);
      const fromDate = new Date(dateFrom);
      const toDate = new Date(dateTo);

      const matchesDateFrom = !dateFrom || billDate >= fromDate;
      const matchesDateTo = !dateTo || billDate <= toDate;
      const matchesSupplierName =
        !supplierName || bill.suppname?.toLowerCase().includes(supplierName.toLowerCase());
      const matchesBillComment =
        !billComment || bill.buybillcomment?.toLowerCase().includes(billComment.toLowerCase());
      const matchesbuybillno =
        !buybillno || bill.buybillno?.toLowerCase().includes(buybillno.toLowerCase());
      const matchesCancelStatus = showAll || !bill.buybillcancel;

      return (
        matchesDateFrom &&
        matchesDateTo &&
        matchesSupplierName &&
        matchesbuybillno &&
        matchesBillComment &&
        matchesCancelStatus
      );
    });

    setFilteredBuyBills(filtered);
  };

  // Define the columns for the CustomTable
  const columns = [
    {
      key: 'buybilldate',
      label: 'วันที่',
      sortable: true,
      render: (row) => row.buybilldate,
    },
    {
      key: 'suppname',
      label: 'ชื่อผู้จำหน่าย',
      sortable: true,
      render: (row) => row.suppname,
    },
    {
      key: 'buybillno',
      label: 'เลขที่บิล',
      sortable: true,
      render: (row) => row.buybillno,
    },
    {
      key: 'buybillcomment',
      label: 'หมายเหตุ',
      sortable: true,
      render: (row) => row.buybillcomment || '-',
    },
    {
      key: 'buybilldisc',
      label: 'ส่วนลดท้ายบิล',
      sortable: true,
      render: (row) => (row.buybilldisc != null ? row.buybilldisc.toLocaleString() : '0'),
      className: 'text-end',
    },
    {
      key: 'id',
      label: 'ID บิล',
      sortable: true,
      render: (row) => row.id,
      className: 'text-center',
    },
    {
      key: 'buybillcancel',
      label: 'ยกเลิก',
      sortable: true,
      render: (row) => (row.buybillcancel ? 'ใช่' : 'ไม่ใช่'),
    },
  ];

  // Initialize selected columns in Redux store if not already set
  useEffect(() => {
    if (selectedColumns.length === 0 && columns.length > 0) {
      const allColumnKeys = columns.map((col) => col.key);
      dispatch(setColumns({ page: 'buy', columns: allColumnKeys }));
    }
  }, [dispatch, selectedColumns, columns]);

  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'buy', columns: newSelectedColumns }));
  };

  // Handle row click
  const handleRowClick = (rowData) => {
    setSelectedRowId(rowData.id);
    setSelectedBuyBill(rowData); // Store the selected buy bill
    if (onBuyBillSelect) {
      onBuyBillSelect(rowData.id);
    }
  };
  // Define rowClassName to highlight selected row
  const rowClassName = (rowData) => {
    return rowData.id === selectedRowId ? 'table-active' : '';
  };

  const handleEditClick = (buyBill) => {
    setSelectedBuyBill(buyBill);
    setShowModal(true); // Open the modal
    setShowEditForm(false); // Reset the edit form state
    setIsDeleteConfirmation(false); // Reset delete confirmation state
  };

  const handleEditAction = () => {
    setShowEditForm(true); // Open the BuyBillEditForm
    setShowModal(false); // Close the DoWithLineModal
  };

  const handleSaveEditForm = async (formData) => {
    try {
      const dataToSend = {
        buybilldate: formData.buyDate,
        buysupp: formData.supplierId, // Use supplierId directly from formData
        buybillcomment: formData.buyComment,
        buybilldisc: formData.billDiscount,
        buybillno: formData.buybillno,
      };
      console.log('Data to send:', dataToSend);
      // Send updated data to the backend
      await axiosInstance.patch(`/Buybillsql/${selectedBuyBill.id}/`, dataToSend);
      const response = await axiosInstance.get('/Buybillv/');
      setBuyBills(response.data);
      setShowEditForm(false); // Close the form after saving

      onDataChange(); // Notify parent about data change
    } catch (error) {
      console.error('Error updating buy bill:', error);
      alert('Error updating buy bill.');
    }
  };

  const handleCancelEditForm = () => {
    setShowEditForm(false); // Close the form without saving
  };

  const onDelete = () => {
    setIsDeleteConfirmation(true);
  };

  const onConfirmDelete = async () => {
    if (selectedBuyBill) {
      try {
        // Update buybillcancel to true for the selected bill
        await axiosInstance.patch(`/Buybillsql/${selectedBuyBill.id}/`, {
          buybillcancel: true,
        });

        // Update buyprodcancel to true for all Buyprodsql records matching buybillidto
        await axiosInstance.patch(`/Buyprodsql/cancel_by_buybillid/`, {
          buybillidto: selectedBuyBill.id,
        });

        // Refresh the buy bills data
        const response = await axiosInstance.get('/Buybillv/');
        setBuyBills(response.data);

        // Notify parent about data change
        onDataChange();

        setShowModal(false);
        setIsDeleteConfirmation(false);
        alert('Buy bill canceled successfully.');
      } catch (error) {
        console.error('Error canceling buy bill:', error);
        alert('Error canceling buy bill.');
      }
    }
  };

  const onCancelDeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  useEffect(() => {
    applyFilters(buyBills, filters);
  }, [buyBills, filters]);

  return (
    <div className="container pt-1">
      {/* Render the filter form */}
      <BuyViewFilterForm
        filters={filters}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
      />

      <CustomTable
        data={filteredBuyBills}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={handleColumnsChange}
        rowsPerPage={8}
        rowIdKey="id"
        storageKey="buyBillTableSortState"
        showEditColumn={true} // Enable the Edit column
        onEdit={handleEditClick} // Pass the edit handler
        selectable={false}
        onRowClick={handleRowClick} // Pass the row click handler
        rowClassName={rowClassName} // Pass the row class name function
      />

      <DoWithLineModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setIsDeleteConfirmation(false);
        }}
        itemName={selectedBuyBill ? selectedBuyBill.buybillcomment : ''}
        onEdit={handleEditAction}
        onDelete={onDelete}
        onConfirmDelete={onConfirmDelete}
        onCancelDeleteConfirmation={onCancelDeleteConfirmation}
        isDeleteConfirmation={isDeleteConfirmation}
        showCreateNew={false}
      />
      {/* BuyBillEditForm modal */}
      {showEditForm && (
        <BuyBillEditForm
          initialData={selectedBuyBill}
          onSave={handleSaveEditForm}
          onCancel={handleCancelEditForm} // Handle close via the Cancel button
          onClose={() => setShowEditForm(false)} // Ensure that onClose closes the modal
        />
      )}
    </div>
  );
}

export default BuyBillViewTable;