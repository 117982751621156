// src/components/sell_page/sellview/SellBillViewTable.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/apiClient';
import CustomTable from '../../../utils/CustomTable';
import SellViewFilterForm from './SellViewFilterForm';
import DoWithLineModal from '../../../utils/DoWithLineModal';
import SellBillEditForm from './sell_edit/SellBillEditForm';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../../redux/slices/columnSlice';
import { Modal } from 'react-bootstrap';
import SellReceipt from '../sellreceipt/SellReceipt'; // Adjust the import path as needed

function SellBillViewTable({ 
  onSellBillSelect, 
  onFilterChange, 
  filters, 
  onDataChange, 
  onClearFilters,
  dataVersion,
}) {
  const [sellBills, setSellBills] = useState([]);
  const [filteredSellBills, setFilteredSellBills] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedSellBill, setSelectedSellBill] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'sell'));
  const dispatch = useDispatch();
  const [showReceipt, setShowReceipt] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch sell bills with totals
        const billsResponse = await axiosInstance.get('/SellbillTotals/');
        // Set sell bills
        setSellBills(billsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [filters, dataVersion]);
  
  // Apply filters whenever sellBills or filters change

  const applyFilters = (data, filters) => {
    const { dateFrom, dateTo, customerName, billComment, showAll } = filters;

    const filtered = data.filter((bill) => {
      const billDate = new Date(bill.sellbilldatetime);
      const fromDate = new Date(dateFrom);
      const toDate = new Date(dateTo);

      const matchesDateFrom = !dateFrom || billDate >= fromDate;
      const matchesDateTo = !dateTo || billDate <= toDate;
      const matchesCustomerName =
        !customerName || bill.nickname?.toLowerCase().includes(customerName.toLowerCase());
      const matchesBillComment =
        !billComment || bill.sellbillcomment?.toLowerCase().includes(billComment.toLowerCase());
      const matchesCancelStatus = showAll || !bill.sellbillcancel;

      return (
        matchesDateFrom &&
        matchesDateTo &&
        matchesCustomerName &&
        matchesBillComment &&
        matchesCancelStatus
      );
    });

    setFilteredSellBills(filtered);
  };

  // Define the columns for the CustomTable
  const columns = [
    {
      key: 'sellbilldatetime',
      label: 'วันที่',
      sortable: true,
      render: (row) => row.sellbilldatetime,
    },
    {
      key: 'nickname',
      label: 'ชื่อลูกค้า',
      sortable: true,
      render: (row) => row.nickname,
    },
    {
      key: 'sellbillcomment',
      label: 'หมายเหตุ',
      sortable: false,
      render: (row) => row.sellbillcomment || '-',
    },
    {
      key: 'billprodsellprice',
      label: 'ยอดสินค้า',
      sortable: false,
      render: (row) => (row.billprodsellprice || 0).toLocaleString(),
      className: 'text-end',
    },
    {
      key: 'billservsellprice',
      label: 'ค่าแรง',
      sortable: false,
      render: (row) => (row.billservsellprice || 0).toLocaleString(),
      className: 'text-end',
    },
    {
      key: 'sellbilldiscount',
      label: 'ลด',
      sortable: true,
      render: (row) => (row.sellbilldiscount != null ? row.sellbilldiscount.toLocaleString() : '0'), // Fallback to '0' if sellbilldiscount is null or undefined
      className: 'text-end',
    },
    {
      key: 'totalBill',
      label: 'ยอดบิล', // Total Bill
      sortable: false,
      render: (row) => {
        const billProdSellPrice = Number(row.billprodsellprice) || 0;
        const billServSellPrice = Number(row.billservsellprice) || 0;
        const sellBillDiscount = Number(row.sellbilldiscount) || 0;
    
        const total = billProdSellPrice + billServSellPrice - sellBillDiscount;
        return total.toLocaleString();
      },
      className: 'text-end',
    },
    {
      key: 'id',
      label: 'ID บิล',
      sortable: true,
      render: (row) => row.id,
      className: 'text-center',
    },
    {
      key: 'sellbillcancel',
      label: 'ยกเลิก',
      sortable: true,
      render: (row) => (row.sellbillcancel ? 'Yes' : 'No'),
    }
  ];

  // Initialize selected columns in Redux store if not already set
  useEffect(() => {
    if (selectedColumns.length === 0 && columns.length > 0) {
      // Set selected columns to show all columns by default if there are no selected columns in Redux
      const allColumnKeys = columns.map((col) => col.key);
      dispatch(setColumns({ page: 'sell', columns: allColumnKeys }));
    }
  }, [dispatch, selectedColumns, columns]);

  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'sell', columns: newSelectedColumns }));
  };

  // Handle row click
  const handleRowClick = (rowData) => {
    setSelectedRowId(rowData.id);
    setSelectedSellBill(rowData); // Store the selected sell bill
    if (onSellBillSelect) {
      onSellBillSelect(rowData.id);
    }
  };
  // Define rowClassName to highlight selected row
  const rowClassName = (rowData) => {
    return rowData.id === selectedRowId ? 'table-active' : '';
  };

  const handleEditClick = (sellBill) => {
    setSelectedSellBill(sellBill);
    setShowModal(true); // Open the modal
    setShowEditForm(false); // Reset the edit form state
    setIsDeleteConfirmation(false); // Reset delete confirmation state
  };

  const handleEditAction = () => {
    setShowEditForm(true); // Open the SellBillEditForm
    setShowModal(false); // Close the DoWithLineModal
  };

  const handleSaveEditForm = async (formData) => {
    try {
      // Fetch the customer based on nickname
      const customerResponse = await axiosInstance.get(`/Customersql`, {
        params: {
          nickname: formData.customer, // Pass the nickname as a query parameter
        },
      });
      // Check if we found a customer with that nickname
      if (customerResponse.data.length === 0) {
        throw new Error('No customer found with the given nickname.');
      }
  
      const idcustomer = customerResponse.data[0].idcustomer; // Get the first customer's ID
      const dataToSend = {
        sellbilldatetime: formData.sellDate,
        sellbillcustomerid: idcustomer, // Use idcustomer instead of nickname
        sellbillcomment: formData.sellComment,
        sellbilldiscount: formData.billDiscount,
        sellbillid: selectedSellBill.id // Include sellbillid from selectedSellBill
      };
      // Send updated data to the backend
      await axiosInstance.patch(`/Sellbillsql/${selectedSellBill.id}/`, dataToSend);
      const response = await axiosInstance.get('/SellbillTotals/');
      setSellBills(response.data);
      setShowEditForm(false); // Close the form after saving

      onDataChange(); // Notify parent about data change
    } catch (error) {
      console.error('Error updating sell bill:', error);
      alert('Error updating sell bill.');
    }
  };

  const handleCancelEditForm = () => {
    setShowEditForm(false); // Close the form without saving
  };

  const onDelete = () => {
    setIsDeleteConfirmation(true);
  };

  const onConfirmDelete = async () => {
    if (selectedSellBill) {
      try {
        // Update sellbillcancel to true for the selected bill
        await axiosInstance.patch(`/Sellbillsql/${selectedSellBill.id}/`, {
          sellbillcancel: true,
        });
  
        // Update sellprodcancel to true for all Sellprodsql records matching sellbillidto
        await axiosInstance.patch(`/Sellprodsql/cancel_by_sellbillid/`, {
          sellbillidto: selectedSellBill.id,
        });
  
        // Optionally, refresh the sell bills data
        const response = await axiosInstance.get('/SellbillTotals/');
        setSellBills(response.data);

        // Notify parent about data change
        onDataChange();
  
        setShowModal(false);
        setIsDeleteConfirmation(false);
        alert('Sell bill canceled successfully.');
      } catch (error) {
        console.error('Error canceling sell bill:', error);
        alert('Error canceling sell bill.');
      }
    }
  };

  const onCancelDeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  useEffect(() => {
    applyFilters(sellBills, filters);
  }, [sellBills, filters]);

  const handlePrintAction = () => {
    if (selectedSellBill) {
      setShowReceipt(true);
    }
  };


  return (
    <div className="container pt-1">
      {/* Render the filter form */}
      <SellViewFilterForm filters={filters} onFilterChange={onFilterChange} onClearFilters={onClearFilters}/>

      <CustomTable
        data={filteredSellBills}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={handleColumnsChange}
        rowsPerPage={8}
        rowIdKey="id"
        storageKey="sellBillTableSortState"
        showEditColumn={true} // Enable the Edit column
        onEdit={handleEditClick} // Pass the edit handler
        selectable={false}
        onRowClick={handleRowClick} // Pass the row click handler
        rowClassName={rowClassName} // Pass the row class name function
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-primary"
          onClick={handlePrintAction}
          disabled={!selectedSellBill}
        >
          Print Receipt
        </button>
    </div>
      <DoWithLineModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setIsDeleteConfirmation(false);
        }}
        itemName={selectedSellBill ? selectedSellBill.sellbillcomment : ''}
        onEdit={handleEditAction}
        onDelete={onDelete}
        onConfirmDelete={onConfirmDelete}
        onCancelDeleteConfirmation={onCancelDeleteConfirmation}
        isDeleteConfirmation={isDeleteConfirmation}
        showCreateNew={false}
      />
      {/* SellBillEditForm modal */}
      {showEditForm && (
        <SellBillEditForm
        initialData={selectedSellBill}
        onSave={handleSaveEditForm}
        onCancel={handleCancelEditForm} // Handle close via the Cancel button
        onClose={() => setShowEditForm(false)} // Ensure that onClose closes the modal
      />
      )}
      {showReceipt && selectedSellBill && (
        <Modal show={showReceipt} onHide={() => setShowReceipt(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SellReceipt sellBillId={selectedSellBill.id} handleCloseReceipt={() => setShowReceipt(false)} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
export default SellBillViewTable;