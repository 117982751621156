// src/components/sell_page/sellreceipt/TestPrintButton.js
import React, { useEffect } from 'react';
import axios from 'axios';

function TestPrintButton() {
  const ipAddress = '192.168.1.39'; // Replace with your actual IP address when testing on a device
  const port = '8000'; // Django default port
  const sampleDataUrl = `http://${ipAddress}:${port}/api/print-test/`;

  const bluetoothPrintUrl = `bprint://${encodeURIComponent(sampleDataUrl)}`;

  useEffect(() => {
    console.log('Bluetooth Print URL:', bluetoothPrintUrl);

    // Test the API and log the response
    axios
      .get(sampleDataUrl)
      .then((response) => {
        console.log('Print Test API Response:', response.data);
      })
      .catch((error) => {
        console.error('Error calling Print Test API:', error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div>
      <a href={bluetoothPrintUrl}>
        <button>Test Print</button>
      </a>
    </div>
  );
}

export default TestPrintButton;