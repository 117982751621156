// src/components/main_sql/SupplierTable.js

import React from 'react';
import SqlTable from '../product_sql/SqlTable'; // Adjust the import path as needed

function SupplierTable({ onSelect, onClose }) {
  const columns = [
    { key: 'suppid', label: 'ID' },
    { key: 'suppname', label: 'Supplier Name' },
  ];

  const fields = [
    { key: 'suppname', label: 'ชื่อผู้จำหน่าย', required: true },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/suppsql/" // Endpoint for fetching supplier data
      columns={columns}
      itemName="ผู้จำหน่าย" // Item name for display (Supplier in Thai)
      dataKey="suppname" // Key used for filtering and displaying supplier name
      idKey="suppid" // Primary key for the item
      pageName="supplier" // Page identifier for Redux state
      storageKey="supplierTableSortState" // Key to store column state in localStorage
      cancelKey="suppcancel" // Field to identify canceled items
      fields={fields} // Pass the fields to SqlTable
    />
  );
}

export default SupplierTable;