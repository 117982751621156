// src/components/sell_page/sellview/SellViewFilterForm.js

import React, { useEffect } from 'react';
import { getToday } from '../../../utils/dateFunc'; // Import CustomerTable

function SellViewFilterForm({ filters, onFilterChange, onClearFilters }) {
    const FILTER_STORAGE_KEY = 'sellViewFilters';

    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem(FILTER_STORAGE_KEY));
        if (savedFilters) {
          onFilterChange({ ...filters, ...savedFilters });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); // Run only on mount
    

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        const updatedFilters = {
        ...filters,
        [name]: newValue, 
        };

        onFilterChange(updatedFilters);

        // Save updated filters to localStorage
        localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));

    };

    const handleClearForm = () => {
        const updatedFilters = {
            dateFrom: getToday(),
            dateTo: getToday(),
            customerName: '',
            billComment: '',
            showAll: false,
        };

        onFilterChange(updatedFilters);

        // Save updated filters to localStorage
        localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));

        // Notify parent component that filters have been cleared
        if (onClearFilters) {
            onClearFilters();
        }
    }

    return (
        <form className="sell-view-filter-form">
            <div style={{ display: 'flex'}}>
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '200px', alignItems: 'flex-end', justifyContent: 'center', height: '100%',marginRight: '6px' }}>
                    <div className="form-group" style={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
                        <label style={{ marginRight: '5px',justifyContent: 'center' }} htmlFor="dateFrom">ตั้งแต่: </label>
                        <input
                        type="date"
                        id="dateFrom"
                        name="dateFrom"
                        value={filters.dateFrom}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '150px', width: '100%', marginBottom: '10px', height: '40px' }}
                        />
                    </div>
                    <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ marginRight: '5px' }} htmlFor="dateTo">ถึง: </label>
                        <input
                        type="date"
                        id="dateTo"
                        name="dateTo"
                        value={filters.dateTo}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '150px', width: '100%', marginBottom: '10px', height: '40px' }}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '200px', alignItems: 'flex-end', justifyContent: 'center', height: '100%' ,marginRight: '6px'}}>
                    <div className="form-group" style={{ display: 'flex', maxWidth: '173px', alignItems: 'center' }}>
                        <label style={{ marginRight: '5px' }} htmlFor="customerName">ลูกค้า: </label>
                        <input
                        type="text"
                        id="customerName"
                        name="customerName"
                        value={filters.customerName}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '150px', width: '100%', marginBottom: '10px', height: '40px' }}
                        />
                    </div>
                    <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ marginRight: '5px' }}  htmlFor="billComment">หมายเหตุ:</label>
                        <input
                        type="text"
                        id="billComment"
                        name="billComment"
                        value={filters.billComment}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '150px', width: '100%', marginBottom: '10px', height: '40px' }}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', alignItems: 'flex-end', justifyContent: 'center', height: '100%' ,marginRight: '6px'}}>
                
                <div className="form-group form-check" style={{ display: 'flex', flexDirection: 'column', maxWidth: '120px', alignItems: 'flex-end', justifyContent: 'center', height: '100%' ,marginRight: '6px',marginTop: '5px'}}>
                    <div className="form-group">  
                        <input
                        type="checkbox"
                        id="showAll"
                        name="showAll"
                        checked={filters.showAll}
                        onChange={handleChange}
                        className="mt-2 form-check-input"
                        style={{marginRight: '6px'}}
                        />
                    <div className="form-group mt-1 mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                        <label className="form-check-label" htmlFor="showAll">
                        ดูที่ยกเลิก
                        </label>
                        </div>
                    
                     {/* Existing Clear and All Button */}
                    <button type="button" className="btn btn-secondary me-2" onClick={handleClearForm}>
                        เคลียร์
                    </button>
                    </div>
                    </div>
                </div>
            </div>  
        </form>
    );
}

export default SellViewFilterForm;