// src/components/buy_page/BuyProdTable.js

import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../utils/apiClient';
import PaginationComponent from '../Pagination';
import { Modal, Button } from 'react-bootstrap';

function BuyProdTable({ buyProdData, fetchBuyProdData, onTotalSumChange }) {
  const [data, setData] = useState([]);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // State for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // Initialize data from buyProdData when the component mounts
  useEffect(() => {
    const fetchedData = buyProdData.map((item) => ({
      ...item,
      qty: Number(item.buyprodqtytoadd) || 0,
      price: Number(item.buyprodunitprictoadd) || 0,
      buyprodunitdisctoadd: Number(item.buyprodunitdisctoadd) || 0,
    }));
    setData(fetchedData);
  }, [buyProdData]);

  // Memoize sortedData to prevent unnecessary recalculations
  const sortedData = useMemo(() => {
    let sorted = [...data];
    if (sortColumn) {
      sorted.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        // Handle undefined values
        if (aValue === undefined || aValue === null) return 1;
        if (bValue === undefined || bValue === null) return -1;

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [data, sortColumn, sortDirection]);

  // Handle sorting
  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortColumn === columnKey && sortDirection === 'asc') {
      direction = 'desc';
    }
    setSortColumn(columnKey);
    setSortDirection(direction);
  };

  // Handle input change for editable cells
  const handleInputChange = (id, field, value) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.idbuyprodtoadd === id ? { ...row, [field]: 
          field === 'buyprodqtytoadd' || 
          field === 'buyprodunitprictoadd' || 
          field === 'buyprodunitdisctoadd' 
            ? Number(value) || 0 
            : value 
          } 
        : row
      )
    );
  };

  // Function to handle saving data to the backend
  const handleSave = async (id) => {
    try {
      const updatedRow = data.find((row) => row.idbuyprodtoadd === id);
      await axiosInstance.patch(`/Buyprodtoadd/${id}/`, updatedRow);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  // Function to handle row deletion
  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await axiosInstance.delete(`/Buyprodtoadd/${selectedRow.idbuyprodtoadd}/`);
        setData((prevData) => prevData.filter((row) => row.idbuyprodtoadd !== selectedRow.idbuyprodtoadd));
        setShowDeleteModal(false); // Close the modal after deletion
        setSelectedRow(null); // Reset selected row
        fetchBuyProdData();
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }
  };
   
  // Open the delete confirmation modal
  const openDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  // Close the delete confirmation modal
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedRow(null);
  };

  // Calculate the total sum
  const totalSum = useMemo(() => {
    return data.reduce((acc, row) => acc + ((row.buyprodunitprictoadd - row.buyprodunitdisctoadd) * row.buyprodqtytoadd), 0);
  }, [data]);

  // Notify parent component of totalSum changes
  useEffect(() => {
    if (onTotalSumChange) {
      onTotalSumChange(totalSum);
    }
  }, [totalSum, onTotalSumChange]);

  function formatNumberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function removeCommas(value) {
    return value.replace(/,/g, '');
  }

  const handleFormattedInputChange = (id, field, value) => {
    const numericValue = removeCommas(value); // Remove commas to get the pure numeric value
    handleInputChange(id, field, numericValue); // Update the state with numeric value
  };
  const handleBlur = (id, field, value) => {
    handleSave(id); // Save the numeric value
  };

  // Define the function to render table rows for the PaginationComponent
  const renderTableRows = (currentRows) => (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th onClick={() => handleSort('categoryname')}>
            กลุ่ม {sortColumn === 'categoryname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('typename')}>
            ประเภท {sortColumn === 'typename' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('brandname')}>
            ยี่ห้อ {sortColumn === 'brandname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('modelname')}>
            รุ่น {sortColumn === 'modelname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('sizename')}>
            ไซส์ {sortColumn === 'sizename' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('colorname')}>
            สี {sortColumn === 'colorname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('prodbarcode')}>
            บาร์โค้ด {sortColumn === 'prodbarcode' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('qty')}>
            จำนวน {sortColumn === 'qty' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('price')}>
            ราคา {sortColumn === 'price' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('discount')}>
            ส่วนลด {sortColumn === 'discount' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('sum')}>
            รวม {sortColumn === 'sum' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th style={{ width: '10px' }}></th>
        </tr>
      </thead>
      <tbody>
        {currentRows.map((row) => (
          <tr key={row.idbuyprodtoadd}>
            <td>{row.categoryname}</td>
            <td>{row.typename}</td>
            <td>{row.brandname}</td>
            <td>{row.modelname}</td>
            <td>{row.sizename}</td>
            <td>{row.colorname}</td>
            <td>{row.prodbarcode}</td>
            <td style={{ textAlign: 'right' }}>
              <input
                type="text"
                value={formatNumberWithCommas(row.buyprodqtytoadd || 0)} // Show formatted value with commas
                onChange={(e) => handleFormattedInputChange(row.idbuyprodtoadd, 'buyprodqtytoadd', e.target.value)}
                onFocus={(e) => e.target.value = row.buyprodqtytoadd || ''} // On focus, show the raw numeric value
                onBlur={(e) => {
                  e.target.value = formatNumberWithCommas(row.buyprodqtytoadd || 0); // On blur, format with commas
                  handleBlur(row.idbuyprodtoadd, 'buyprodqtytoadd', e.target.value);
                }}
                style={{ width: `${(row.buyprodqtytoadd || 0).toString().length + 2.5}ch`, height: '32px', textAlign: 'right' }}
              />
            </td>
            <td style={{ textAlign: 'right' }}>
              <input
                type="text"
                value={formatNumberWithCommas(row.buyprodunitprictoadd || 0)} // Show formatted value with commas
                onChange={(e) => handleFormattedInputChange(row.idbuyprodtoadd, 'buyprodunitprictoadd', e.target.value)}
                onFocus={(e) => e.target.value = row.buyprodunitprictoadd || ''} // On focus, show the raw numeric value
                onBlur={(e) => {
                  e.target.value = formatNumberWithCommas(row.buyprodunitprictoadd || 0); // On blur, format with commas
                  handleBlur(row.idbuyprodtoadd, 'buyprodunitprictoadd', e.target.value);
                }}
                style={{ width: `${(row.buyprodunitprictoadd || 0).toString().length + 2.5}ch`, height: '32px', textAlign: 'right' }}
              />
            </td>
            <td style={{ textAlign: 'right' }}>
              <input
                type="text"
                value={formatNumberWithCommas(row.buyprodunitdisctoadd || 0)} // Show formatted value with commas
                onChange={(e) => handleFormattedInputChange(row.idbuyprodtoadd, 'buyprodunitdisctoadd', e.target.value)}
                onFocus={(e) => e.target.value = row.buyprodunitdisctoadd || ''} // On focus, show the raw numeric value
                onBlur={(e) => {
                  e.target.value = formatNumberWithCommas(row.buyprodunitdisctoadd || 0); // On blur, format with commas
                  handleBlur(row.idbuyprodtoadd, 'buyprodunitdisctoadd', e.target.value);
                }}
                style={{ width: `${(row.buyprodunitdisctoadd || 0).toString().length + 2.5}ch`, height: '32px', textAlign: 'right' }}
              />
            </td>
            <td style={{ textAlign: 'right', paddingTop: '12px' }}>
              {(row.buyprodunitprictoadd - row.buyprodunitdisctoadd) * row.buyprodqtytoadd
                ? formatNumberWithCommas((row.buyprodunitprictoadd - row.buyprodunitdisctoadd) * row.buyprodqtytoadd)
                : 0}
            </td>
            <td>
              <span
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => openDeleteModal(row)}
              >
                ลบ
              </span>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="10" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            รวม
          </td>
          <td style={{ textAlign: 'right' }}>{formatNumberWithCommas(totalSum)}</td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  );

  return (
    <div>
      <PaginationComponent
        data={sortedData}
        rowsPerPage={rowsPerPage}
        renderTableRows={renderTableRows}
      />
      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>ยืนยันการลบ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <p>
              คุณต้องการลบสินค้า: {selectedRow.categoryname} {selectedRow.typename}{' '}
              {selectedRow.brandname} {selectedRow.modelname} {selectedRow.sizename} {' '}
              {selectedRow.colorname} ใช่หรือไม่?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            ยกเลิก
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            ลบ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BuyProdTable;