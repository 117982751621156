// src/components/sell_page/sellreceipt/SellReceipt.js

import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../../utils/apiClient';
import './SellReceipt.css';
import TestPrintButton from './TestPrintButton'; // Import TestPrintButton

function SellReceipt({ sellBillId, handleCloseReceipt }) {
  const [sellBillData, setSellBillData] = useState(null);
  const receiptRef = useRef();

  useEffect(() => {
    if (sellBillId) {
      fetchSellBillData(sellBillId);
    }
  }, [sellBillId]);

  const fetchSellBillData = async (id) => {
    try {
      const response = await axiosInstance.get(`/Sellbillv/${id}/`);
      setSellBillData(response.data);
      console.log('Sell Bill Data:', response.data);
      console.log('Sell Products:', response.data.sell_products);
      console.log('Sell Services:', response.data.sell_services);
      console.log('Sell Jobs:', response.data.sell_jobs);
    } catch (error) {
      console.error('Error fetching sell bill data:', error);
    }
  };

  const handlePrint = () => {
    const printContent = receiptRef.current.innerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <body onload="window.print(); window.close();">
          ${printContent}
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  if (!sellBillData) {
    return <div>Loading receipt...</div>;
  }

  const { sell_products = [], sell_services = [], sell_jobs = [] } = sellBillData;

  return (
    <div className="sell-receipt-container">
      <div className="sell-receipt" ref={receiptRef}>
          {/* Inline Style Block */}
          <style>
            {`
              .receipt-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
              }

              .receipt-header h2 {
                margin: 0;
                font-size: 1.5rem;
              }

              .receipt-header p {
                margin: 0;
              }
            `}
          </style>

          <div className="receipt-header">
            <h2>ใบเสร็จ</h2>
            <p>เลขที่: {sellBillData.id}</p>
            <p>วันที่: {sellBillData.sellbilldatetime}</p>
          </div>

        {/* Customer Information */}
        <div className="customer-info">
          <p><strong>Customer:</strong> {sellBillData.nickname || 'N/A'}</p>
          {/* Include other customer details if available */}
        </div>

        {/* Products Table */}
          {sell_products.length > 0 && (
            <div className="products-table">
              <h3>Products</h3>
              <table>
                <thead>
                  <tr>
                    <th className="text-center">สินค้า</th>
                    <th className="text-center">ชิ้น</th>
                    <th className="text-center">ราคา</th>
                    <th className="text-center">ลด</th>
                    <th className="text-center">รวม</th>
                  </tr>
                </thead>
                <tbody>
                  {sell_products.map((item, index) => {
                    const discount = Number(item.sellproddis) || 0;
                    const unitPrice = Number(item.sellprodprice) || 0;
                    const totalUnitPrice = unitPrice - discount;
                    const amount = Number(item.sellprodqty) || 0;
                    const totalPrice = totalUnitPrice * amount;
                    return (
                      <tr key={item.idsellproduct || `product-${index}`}>
                        <td>{item.typename} {item.brandname} {item.modelname} {item.sizename} {item.colorname}</td>
                        <td className="text-center">{amount.toLocaleString()}</td>
                        <td className="text-end">{unitPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                        <td className="text-end">{discount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                        <td className="text-end">{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4" className="text-end"><strong>Total Product:</strong></td>
                    <td className="text-end">
                      <strong>
                        {sell_products.reduce((sum, item) => {
                          const discount = Number(item.sellproddis) || 0;
                          const unitPrice = Number(item.sellprodprice) || 0;
                          const totalUnitPrice = unitPrice - discount;
                          const amount = Number(item.sellprodqty) || 0;
                          const totalPrice = totalUnitPrice * amount;
                          return sum + totalPrice;
                        }, 0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}

       {/* Services with Associated Jobs */}
          {sell_services.length > 0 && (
            <div className="services-section">
              {sell_services.map((service, serviceIndex) => {
                const jobsForService = sell_jobs.filter(job => job.servsellid === service.idservicesell);

                // Calculate the total price for all jobs associated with this service
                const totalJobPrice = jobsForService.reduce((sum, job) => {
                  const unitPrice = Number(job.servjobprice) || 0;
                  const discount = Number(job.servjobdisc) || 0;
                  const quantity = Number(job.servjobqty) || 0;
                  return sum + (unitPrice - discount) * quantity;
                }, 0);

                return (
                  <div key={service.idservicesell || `service-${serviceIndex}`}>
                    <p>งาน Service : {service.categoryname} {service.brandname} {service.brandname}</p>
                    <table className="jobs-table">
                      <thead>
                        <tr>
                          <th>งาน</th>
                          <th>ชิ้น</th>
                          <th>ราคา</th>
                          <th>ลด</th>
                          <th>รวม</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobsForService.map((job, jobIndex) => {
                          const unitPrice = Number(job.servjobprice) || 0;
                          const discount = Number(job.servjobdisc) || 0;
                          const quantity = Number(job.servjobqty) || 0;
                          const totalPrice = (unitPrice - discount) * quantity;
                          return (
                            <tr key={job.idservjob || `job-${jobIndex}`}>
                              <td>{job.servicename}</td>
                              <td>{quantity.toLocaleString()}</td>
                              <td className="text-end">{unitPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                              <td className="text-end">{discount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                              <td className="text-end">{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="4" className="text-end"><strong>Total Job:</strong></td>
                          <td className="text-end">
                            <strong>{totalJobPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                );
              })}
            </div>
          )}

        {/* Total Amount */}
        <div className="total-amount">
          <h3 className="text-end">
            <strong>Total Bill:</strong> {calculateTotal().toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </h3>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="action-buttons">
        <button onClick={handlePrint}>Print Receipt</button>
        <button onClick={handleCloseReceipt}>Close</button>
      </div>

      <h1>Bluetooth Print Test</h1>
      <TestPrintButton />
    </div>
  );

  // Helper function to calculate the total amount
  function calculateTotal() {
    // Calculate Total Product
    const productsTotal = sell_products.reduce((sum, item) => {
      const discount = Number(item.sellproddis) || 0;
      const unitPrice = Number(item.sellprodprice) || 0;
      const totalUnitPrice = unitPrice - discount;
      const amount = Number(item.sellprodqty) || 0;
      return sum + (totalUnitPrice * amount);
    }, 0);
  
    // Calculate Total Job across all services
    const servicesTotal = sell_services.reduce((sum, service) => {
      const serviceJobsTotal = sell_jobs
        .filter(job => job.servsellid === service.idservicesell)
        .reduce((jobSum, job) => {
          const unitPrice = Number(job.servjobprice) || 0;
          const discount = Number(job.servjobdisc) || 0;
          const quantity = Number(job.servjobqty) || 0;
          return jobSum + (unitPrice - discount) * quantity;
        }, 0);
      return sum + serviceJobsTotal;
    }, 0);
  
    // Calculate final total with discount
    const discount = Number(sellBillData.sellbilldiscount) || 0;
    return productsTotal + servicesTotal - discount;
  }
}

export default SellReceipt;