// src/components/buy_page/buyview/BuyProdViewTable.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/apiClient';
import CustomTable from '../../../utils/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../../redux/slices/columnSlice';
import DoWithLineModal from '../../../utils/DoWithLineModal';

function BuyProdViewTable({ buyBillId, showAll, dataVersion, onDataChange }) {
  const [buyProds, setBuyProds] = useState([]);
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'buyProd'));
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedBuyProd, setSelectedBuyProd] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  // Fetch buy products based on buyBillId and apply the showAll filter
  const fetchBuyProds = async (buyBillId) => {
    try {
      const response = await axiosInstance.get('/Buyprodv/', {
        params: {
          buybillidto: buyBillId,
        },
      });

      // If showAll is true, show all products. Otherwise, filter out canceled products.
      const filteredProds = showAll
        ? response.data
        : response.data.filter(prod => !prod.buyprodcancel);

      // Map over the data and set default values for null fields
      const processedProds = filteredProds.map((prod) => ({
        ...prod,
        buyprodunitpric: prod.buyprodunitpric != null ? prod.buyprodunitpric : 0,
        buyprodunitdisc: prod.buyprodunitdisc != null ? prod.buyprodunitdisc : 0,
        buyprodqty: prod.buyprodqty != null ? prod.buyprodqty : 0,
      }));

      setBuyProds(processedProds);
    } catch (error) {
      console.error('Error fetching buy products:', error);
    }
  };

  useEffect(() => {
    if (buyBillId) {
      fetchBuyProds(buyBillId);
    } else {
      setBuyProds([]); // Set buyProds to an empty array if buyBillId is null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyBillId, showAll, dataVersion]);

  // Calculate the total sum
  const calculateTotalSum = () => {
    return buyProds.reduce((acc, row) => {
      const price = Number(row.buyprodunitpric) || 0;
      const discount = Number(row.buyprodunitdisc) || 0;
      const qty = Number(row.buyprodqty) || 0;
      const total = (price - discount) * qty;
      return acc + total;
    }, 0);
  };

  const totalSum = calculateTotalSum();

  const footerData = {
    buyprodqty: {
      content: 'รวม', // 'Total' in Thai
      className: 'footer-total-label', // Custom class name
    },
    sum: {
      content: totalSum.toLocaleString(),
      className: 'footer-sum-price', // Custom class name
    },
  };

  // Define the columns for the CustomTable
  const columns = [
    {
      key: 'categoryname',
      label: 'กลุ่ม',
      sortable: true,
      render: (row) => row.categoryname,
    },
    {
      key: 'typename',
      label: 'ย่อย',
      sortable: true,
      render: (row) => row.typename,
    },
    {
      key: 'brandname',
      label: 'ยี่ห้อ',
      sortable: true,
      render: (row) => row.brandname,
    },
    {
      key: 'modelname',
      label: 'รุ่น',
      sortable: true,
      render: (row) => row.modelname,
    },
    {
      key: 'sizename',
      label: 'ไซส์',
      sortable: true,
      render: (row) => row.sizename,
    },
    {
      key: 'colorname',
      label: 'สี',
      sortable: true,
      render: (row) => row.colorname,
    },
    {
      key: 'buyprodunitpric',
      label: 'ราคา',
      sortable: true,
      render: (row) =>
        row.buyprodunitpric != null ? row.buyprodunitpric.toLocaleString() : '0',
      className: 'text-end',
    },
    {
      key: 'buyprodunitdisc',
      label: 'ลด',
      sortable: true,
      render: (row) =>
        row.buyprodunitdisc != null ? row.buyprodunitdisc.toLocaleString() : '0',
      className: 'text-end',
    },
    {
      key: 'buyprodqty',
      label: 'จำนวน',
      sortable: true,
      render: (row) =>
        row.buyprodqty != null ? row.buyprodqty.toLocaleString() : '0',
      className: 'text-center',
    },
    {
      key: 'sum',
      label: 'รวม',
      sortable: false,
      render: (row) => {
        const price = row.buyprodunitpric != null ? row.buyprodunitpric : 0;
        const discount = row.buyprodunitdisc != null ? row.buyprodunitdisc : 0;
        const qty = row.buyprodqty != null ? row.buyprodqty : 0;
        const total = (price - discount) * qty;
        return total.toLocaleString();
      },
      className: 'text-end',
    },
    {
      key: 'idbuyprod',
      label: 'Buy Product ID',
      sortable: true,
      render: (row) => row.idbuyprod,
      className: 'text-center',
    },
    {
      key: 'buyprodcancel',
      label: 'ยกเลิก',
      sortable: true,
      render: (row) => (row.buyprodcancel ? 'ใช่' : 'ไม่ใช่'),
    },
  ];

  // Initialize selected columns in Redux store if not already set
  useEffect(() => {
    if (selectedColumns.length === 0 && columns.length > 0) {
      // Set selected columns to show all columns by default if there are no selected columns in Redux
      const allColumnKeys = columns.map((col) => col.key);
      dispatch(setColumns({ page: 'buyProd', columns: allColumnKeys }));
    }
  }, [dispatch, selectedColumns, columns]);

  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'buyProd', columns: newSelectedColumns }));
  };

  // Handle Edit click
  const handleEditClick = (buyProd) => {
    setSelectedBuyProd(buyProd);
    setShowModal(true);
    setIsDeleteConfirmation(false); // Reset delete confirmation state
  };

  // Handle Delete action
  const onDelete = () => {
    setIsDeleteConfirmation(true);
  };

  // Confirm Delete action
  const onConfirmDelete = async () => {
    if (selectedBuyProd) {
      try {
        // Update buyprodcancel to true for the selected product
        await axiosInstance.patch(`/Buyprodsql/${selectedBuyProd.idbuyprod}/`, {
          buyprodcancel: true,
        });

        // Refresh the buy products data
        fetchBuyProds(buyBillId);

        // Notify parent about data change
        onDataChange();

        setShowModal(false);
        setIsDeleteConfirmation(false);
        alert('Buy product canceled successfully.');
      } catch (error) {
        console.error('Error canceling buy product:', error);
        alert('Error canceling buy product.');
      }
    }
  };

  const onCancelDeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  return (
    <div className="container">

      <h5>รายการซื้อสินค้า</h5>
      <CustomTable
        data={buyProds}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={handleColumnsChange}
        rowsPerPage={10}
        rowIdKey="idbuyprod" // Use the correct key for product ID
        storageKey="buyProdTableSortState"
        showEditColumn={true} // Enable the Edit column
        onEdit={handleEditClick} // Pass the edit handler
        selectable={false}
        footerData={footerData} // Pass the footer data
        tableClassName="buy-prod-table"
      />
      {/* DoWithLineModal for handling edit/delete actions */}
      <DoWithLineModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setIsDeleteConfirmation(false);
        }}
        itemName={`${selectedBuyProd ? selectedBuyProd.categoryname : ''} ${selectedBuyProd ? selectedBuyProd.typename : ''} ${selectedBuyProd ? selectedBuyProd.brandname : ''} ${selectedBuyProd ? selectedBuyProd.modelname : ''} ${selectedBuyProd ? selectedBuyProd.sizename : ''} ${selectedBuyProd ? selectedBuyProd.colorname : ''}`}
        onEdit={() => {
          // Implement edit functionality if needed
        }}
        onDelete={onDelete}
        onConfirmDelete={onConfirmDelete}
        onCancelDeleteConfirmation={onCancelDeleteConfirmation}
        isDeleteConfirmation={isDeleteConfirmation}
        showCreateNew={false}
      />
    </div>
  );
}

export default BuyProdViewTable;