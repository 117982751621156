// src/components/buy_page/BuyPage.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../../utils/apiClient';
import BuyProdTable from './BuyProdTable';
import BuyBillForm from './BuyBillForm';
import FilterForm from '../FilterForm'; 
import StockTable from '../StockTable'; 
import { Modal } from 'react-bootstrap';
import { getToday } from '../../utils/dateFunc';

function BuyPage() {
  const [buyFormData, setBuyFormData] = useState({
    buyComment: localStorage.getItem('buyComment') || '',
    buyDate: localStorage.getItem('buyDate') || getToday(), // Default to today's date
    supplier: localStorage.getItem('supplier') || '',
    supplierId: localStorage.getItem('supplierId') || '',
    buybilldiscount: parseInt(localStorage.getItem('buybilldiscount')) || 0,
  });

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [stockData, setStockData] = useState([]); 
  const [filters, setFilters] = useState({
    name: '',
    categoryname: '',
    typename: '',
    brandname: '',
    modelname: '',
    sizename: '',
    colorname: '',
    barcode: '',
    suppname: '',
  });
  const [seeAll, setSeeAll] = useState(false);
  const [buyProdData, setBuyProdData] = useState([]); // State to store added products
  const [selectedProduct, setSelectedProduct] = useState([]); // Selected product in modal
  const [totalProdBuy, setTotalProdBuy] = useState(0);
  const netTotal = totalProdBuy - (buyFormData.buybilldiscount || 0);

  const fetchBuyProdData = async () => {
    try {
      const response = await axiosInstance.get('/Buyprodtoaddv/');
      setBuyProdData(response.data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching buyProdData:', error);
    }
  };

  const fetchStockData = useCallback(() => {
    axiosInstance.get(`/stview/`)
      .then(response => {
        setStockData(response.data); 
      })
      .catch(error => {
        console.error('There was an error fetching the stock data!', error);
      });
  }, []);

  useEffect(() => {
    if (showAddProductModal) {
      fetchStockData(); 
    }
  }, [fetchStockData, showAddProductModal]);

  useEffect(() => {
    // Fetch buyProdData when the component mounts
    fetchBuyProdData();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleClearFilters = () => {
    setFilters({
      name: '',
      categoryname: '',
      typename: '',
      brandname: '',
      modelname: '',
      sizename: '',
      colorname: '',
      barcode: '',
      suppname: '',
    });
    fetchStockData(); 
  };

  const handleRefresh = () => {
    fetchStockData(); 
  };

  const handleSeeAllChange = () => {
    setSeeAll(!seeAll);
  };

  const filteredStockData = useMemo(() => {
    return stockData.filter(item => {
      const matchesFilters = (
        (item.name || '').toLowerCase().includes(filters.name.toLowerCase()) &&
        (item.categoryname || '').toLowerCase().includes(filters.categoryname.toLowerCase()) &&
        (item.typename || '').toLowerCase().includes(filters.typename.toLowerCase()) &&
        (item.brandname || '').toLowerCase().includes(filters.brandname.toLowerCase()) &&
        (item.modelname || '').toLowerCase().includes(filters.modelname.toLowerCase()) &&
        (item.sizename || '').toLowerCase().includes(filters.sizename.toLowerCase()) &&
        (item.colorname || '').toLowerCase().includes(filters.colorname.toLowerCase()) &&
        (item.barcode || '').toLowerCase().includes(filters.barcode.toLowerCase()) &&
        (item.suppname || '').toLowerCase().includes(filters.suppname.toLowerCase())
      );
      const matchesSeeAll = seeAll || !item.prodcancel;
      return matchesFilters && matchesSeeAll;
    });
  }, [stockData, filters, seeAll]);

  const handleAddProduct = () => {
    setShowAddProductModal(true);
  };

  const handleCloseModal = () => {
    setShowAddProductModal(false);
  };

  // Function to handle adding selected products
  const handleAddSelectedToBuy = () => {
    if (!selectedProduct || selectedProduct.length === 0) {
      alert("Please select a product to add.");
      return;
    }
  
    const requests = selectedProduct.map(async (product) => {
      const dataToAdd = {
        buyprodidto: product.prodid,
        buyprodqtytoadd: 1, // Set default qty to 1
        buyprodunitprictoadd: product.price, // Set price from StockTable
      };
      try {
        const response = await axiosInstance.post('/Buyprodtoadd/', dataToAdd);
        return response.data;
      } catch (error) {
        console.error('Error adding product to Buyprodtoadd:', error);
        return null;
      }
    });
  
    Promise.all(requests).then((results) => {
      fetchBuyProdData();
      handleCloseModal();
    });
  };

  // Handle saving the buy
  const handleSaveBuy = async () => {
    try {
      const supplierId = buyFormData.supplierId ? parseInt(buyFormData.supplierId, 10) : null;
      const storedDiscount = localStorage.getItem('buybilldiscount');
      const buybilldiscount = storedDiscount !== null ? parseInt(storedDiscount, 10) : (buyFormData.buybilldiscount || 0);
      const Buybillno = localStorage.getItem('Buybillno');

      console.log("Buy Form Data:", {
        buybillcomment: buyFormData.buyComment,
        buybilldate: buyFormData.buyDate,
        buysupp: supplierId,
        buybilldisc: buybilldiscount,
        buybillno: Buybillno,
      });

      // Step 1: Create Buybillsql record
      const buyBillResponse = await axiosInstance.post('/Buybillsql/', {
        buybillcomment: buyFormData.buyComment ? buyFormData.buyComment : '-',
        buybilldate: buyFormData.buyDate,
        buysupp: supplierId ? supplierId : 1,
        buybilldisc: buybilldiscount,
        buybilltimestamp: new Date().toISOString(),
        buybillno: Buybillno ? Buybillno : '-',
      });
  
      const buyid = buyBillResponse.data.buyid; // Get buyid from the created record

      // Step 2: Fetch data from Buyprodtoadd (which contains the selected products)
      const response = await axiosInstance.get('/Buyprodtoadd/');
      const buyProdToAddData = response.data;
      console.log("BuyProdToAdd Data:", buyProdToAddData);

      // Step 3: Prepare BuyProd data
      const buyProdArray = buyProdToAddData.map((item) => ({
        buyproname: item.buyprodidto,
        buybillidto: buyid,
        buyprodqty: item.buyprodqtytoadd ? item.buyprodqtytoadd : 0,
        buyprodunitpric: item.buyprodunitprictoadd ? item.buyprodunitprictoadd : 0,
        buyprodunitdisc: item.buyprodunitdisctoadd ? item.buyprodunitdisctoadd : 0,
        buyprodtimestamp: new Date().toISOString(),
      }));
      console.log("Buy Product Array:", buyProdArray);

      // Step 4: Create BuyProd records
      await axiosInstance.post('/Buyprodsql/bulk_create/', buyProdArray);

      // Step 5: Clear the BuyProdToAdd table after saving
      await axiosInstance.post('/Buyprodtoadd/delete-all/');
      setBuyProdData([]); // Clear local data

      // Step 6: Clear local storage values
      localStorage.removeItem('buyComment');
      localStorage.removeItem('supplier');
      localStorage.removeItem('supplierId');
      localStorage.removeItem('buyDate');
      localStorage.removeItem('buybilldiscount');

      // Step 7: Reset the form fields
      setBuyFormData({
        buyComment: '',
        buyDate: getToday(),
        supplier: '',
        supplierId: '',
        buybilldiscount: 0,
      });
        
      alert('Purchase bill saved successfully');
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error('Error saving purchase bill:', error);
      alert('Error saving purchase bill');
    }
  };

  // Handle form data changes
  const handleBuyCommentChange = (updatedData) => {
    setBuyFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  // Handle discount input change
  const handleDiscountChange = (value) => {
    const numericValue = parseInt(value.replace(/,/g, ''), 10) || 0;
    const updatedData = {
      ...buyFormData,
      buybilldiscount: numericValue,
    };
    setBuyFormData(updatedData);
    localStorage.setItem('buybilldiscount', numericValue);
  };

  const handleClearAll = useCallback(async () => {
    const confirmation = window.confirm('Are you sure you want to clear all products?');
    if (confirmation) {
      try {
        // Clear all products in Buyprodtoadd
        await axiosInstance.post('/Buyprodtoadd/delete-all/');
        setBuyProdData([]); // Clear the product data in the table
  
        // Reset the buy bill form fields
        setBuyFormData({
          buyComment: '',
          buyDate: getToday(), // Reset to today's date
          supplier: '',
          supplierId: '',
          buybilldiscount: 0,
        });
  
        // Clear localStorage values
        localStorage.removeItem('supplier');
        localStorage.removeItem('supplierId');
        localStorage.removeItem('buyComment');
        localStorage.removeItem('buyDate');
        localStorage.removeItem('buybilldiscount');
  
        window.location.reload(); // Refresh the page
      } catch (error) {
        console.error('Error clearing all rows and resetting the form:', error);
      }
    }
  }, []);

  function formatNumberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <div className="container mb-0 pt-1">
      <BuyBillForm 
        onChange={handleBuyCommentChange} 
        formData={buyFormData}
      />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex mb-1">
          <h5 className="me-3">รายการสินค้า</h5>
          <button className="btn btn-primary me-2" onClick={handleAddProduct}>
            เพิ่มสินค้า
          </button>
          <button className="btn btn-danger" onClick={handleClearAll}>
            เคลียร์รายการ
          </button>
        </div>
        <div className="d-flex flex-row-reverse mb-1">
          <button className="btn btn-success" onClick={handleSaveBuy}>
            บันทึก
          </button>
        </div>
      </div>
      <BuyProdTable 
        buyProdData={buyProdData} 
        fetchBuyProdData={fetchBuyProdData} 
        handleClearAll={handleClearAll}
        onTotalSumChange={setTotalProdBuy}
      />
      {/* Display totals and discount input */}
      <div className="d-flex mb-2 align-items-center justify-content-end">
        <h5 className="me-3">ยอดรวมสินค้า : {formatNumberWithCommas(totalProdBuy)}</h5>   
        <h5 className="me-3">ส่วนลดท้ายบิล :</h5>   
        <input
          type="text"
          value={formatNumberWithCommas(buyFormData.buybilldiscount || 0)}
          onChange={(e) => handleDiscountChange(e.target.value)}
          onFocus={(e) => e.target.value = buyFormData.buybilldiscount || ''}
          onBlur={(e) => e.target.value = formatNumberWithCommas(buyFormData.buybilldiscount || 0)}
          className="me-3 mb-2"
          style={{
            width: `${(buyFormData.buybilldiscount || 0).toString().length + 3.5}ch`,
            height: '32px',
            textAlign: 'right',
          }}
        />
        <h5 className="me-1">ยอดสุทธิ : </h5> <h3>{formatNumberWithCommas(netTotal)}</h3>
      </div>

      <Modal show={showAddProductModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>เลือกสินค้า</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilterForm
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleClearFilters={handleClearFilters}
            seeAll={seeAll}
            handleSeeAllChange={handleSeeAllChange}
            handleRefresh={handleRefresh}
          />
          <StockTable 
            data={filteredStockData} 
            seeAll={seeAll} 
            selectable={true}
            onSelectionChange={setSelectedProduct} // Capture selected products here          
            hideActions={true} // Pass hideActions as true when modal is displayed
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            ปิด  
          </button>
          <button className="btn btn-warning" onClick={handleAddSelectedToBuy}>
            เพิ่มสินค้า
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BuyPage;