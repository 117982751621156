// src/components/buy_page/buyview/BuyBillViewPage.js

import React, { useState } from 'react';
import BuyBillViewTable from './BuyBillViewTable';
import BuyProdViewTable from './BuyProdViewTable';
import { getToday } from '../../../utils/dateFunc';

function BuyBillViewPage() {
  const [selectedBuyBillId, setSelectedBuyBillId] = useState(null);
  const [dataVersion, setDataVersion] = useState(0);

  const today = getToday();
  const [filters, setFilters] = useState({
    dateFrom: today,
    dateTo: today,
    supplierName: '',
    buybillno: '',
    billComment: '',
    showAll: false,
  });

  const handleBuyBillSelect = (buyBillId) => {
    setSelectedBuyBillId(buyBillId);
    setDataVersion((prevVersion) => prevVersion + 1);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setSelectedBuyBillId(null);
  };

  const handleClearFilters = () => {
    setSelectedBuyBillId(null);
    setDataVersion((prevVersion) => prevVersion + 1);
  };

  const handleDataChange = () => {
    setDataVersion((prevVersion) => prevVersion + 1);
  };

  return (
    <div>
      <BuyBillViewTable
        onBuyBillSelect={handleBuyBillSelect}
        onDataChange={handleDataChange}
        filters={filters}
        onFilterChange={handleFilterChange}
        onClearFilters={handleClearFilters}
        dataVersion={dataVersion}
      />
      <BuyProdViewTable
        buyBillId={selectedBuyBillId}
        showAll={filters.showAll}
        dataVersion={dataVersion}
        onDataChange={handleDataChange}
      />
    </div>
  );
}

export default BuyBillViewPage;