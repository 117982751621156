// src/components/sell_page/sellview/SellBillViewPage.js

import React, { useState } from 'react';
import SellBillViewTable from './SellBillViewTable';
import SellProdViewTable from './SellProdViewTable';
import SellServViewTable from './SellServViewTable';
import SellJobViewTable from './SellJobViewTable';
import { getToday } from '../../../utils/dateFunc';

function SellBillViewPage() {
  const [selectedSellBillId, setSelectedSellBillId] = useState(null);
  const [selectedSellServId, setSelectedSellServId] = useState(null);
  const [dataVersion, setDataVersion] = useState(0);

  const todayThai = getToday();
  const [filters, setFilters] = useState({
    dateFrom: todayThai,
    dateTo: todayThai,
    customerName: '',
    billComment: '',
    showAll: false,
  });

  const handleSellBillSelect = (sellBillId) => {
    setSelectedSellBillId(sellBillId);
    setSelectedSellServId(null);
    setDataVersion((prevVersion) => prevVersion + 1);
  };

  const handleSellServSelect = (sellServId) => {
    setSelectedSellServId(sellServId);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setSelectedSellBillId(null);
    setSelectedSellServId(null);
  };

  const handleClearFilters = () => {
    setSelectedSellBillId(null);
    setSelectedSellServId(null);
    setDataVersion((prevVersion) => prevVersion + 1);
  };

  const handleDataChange = () => {
    setDataVersion((prevVersion) => prevVersion + 1);
  };

  return (
    <div>
      <SellBillViewTable
        onSellBillSelect={handleSellBillSelect}
        onDataChange={handleDataChange}
        filters={filters}
        onFilterChange={handleFilterChange}
        onClearFilters={handleClearFilters}
        dataVersion={dataVersion}
      />
      <SellProdViewTable
        sellBillId={selectedSellBillId}
        showAll={filters.showAll}
        dataVersion={dataVersion}
        onDataChange={handleDataChange}
      />
      <SellServViewTable
        onSellServSelect={handleSellServSelect}
        sellBillId={selectedSellBillId}
        showAll={filters.showAll}
        dataVersion={dataVersion}
        onDataChange={handleDataChange}
      />
      {selectedSellServId && (
        <SellJobViewTable
          sellServId={selectedSellServId}
          showAll={filters.showAll}
          dataVersion={dataVersion}
        />
      )}
    </div>
  );
}

export default SellBillViewPage;