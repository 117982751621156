// src/components/sell_page/sellview/SellProdViewTable.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/apiClient';
import CustomTable from '../../../utils/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../../redux/slices/columnSlice';
import DoWithLineModal from '../../../utils/DoWithLineModal';

function SellProdViewTable({sellBillId,showAll, dataVersion, onDataChange}) {
    const [sellProds, setSellProds] = useState([]);
    const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'sellProd'));
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [selectedSellProd, setSelectedSellProd] = useState(null);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  
    // Fetch sell products based on sellBillId and apply the showAll filter
    const fetchSellProds = async (sellBillId) => {
      try {
        const response = await axiosInstance.get('/Sellprodv/', {
          params: {
            sellbillidto: sellBillId,
          },
        });

        // If showAll is true, show all products. Otherwise, filter out canceled products.
        const filteredProds = showAll 
          ? response.data 
          : response.data.filter(prod => !prod.sellprodcancel);

              // Map over the data and set default values for null fields
        const processedProds = filteredProds.map((prod) => ({
          ...prod,
          sellprodprice: prod.sellprodprice != null ? prod.sellprodprice : 0,
          sellproddis: prod.sellproddis != null ? prod.sellproddis : 0,
          sellprodqty: prod.sellprodqty != null ? prod.sellprodqty : 0,
        }));

        setSellProds(processedProds);
      } catch (error) {
        console.error('Error fetching sell products:', error);
      }
    };

    useEffect(() => {
      if (sellBillId) {
        fetchSellProds(sellBillId);
      } else {
        setSellProds([]); // Set sellProds to an empty array if sellBillId is null
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sellBillId, showAll, dataVersion]);

    // Calculate the total sum for the sumServPrice column
  const calculateTotalSum = () => {
    return sellProds.reduce((acc, job) => {
      const price = Number(job.sellprodprice) || 0;
      const discount = Number(job.sellproddis) || 0;
      const qty = Number(job.sellprodqty) || 0;
      const total = (price - discount) * qty;
      return acc + total;
    }, 0);
  };

  const totalSum = calculateTotalSum();

    const footerData = {
      sellprodqty: {
        content: 'รวม', // 'Total' in Thai
        className: 'footer-total-label', // Custom class name
      },
      sum: {
        content: totalSum.toLocaleString(),
        className: 'footer-sum-price', // Custom class name
      },
    };
  
    // Define the columns for the CustomTable
    const columns = [
      {
        key: 'categoryname',
        label: 'กลุ่ม',
        sortable: true,
        render: (row) => row.categoryname,
      },
      {
        key: 'typename',
        label: 'ย่อย',
        sortable: true,
        render: (row) => row.typename,
      },
      {
        key: 'brandname',
        label: 'ยี่ห้อ',
        sortable: true,
        render: (row) => row.brandname,
      },
      {
        key: 'modelname',
        label: 'รุ่น',
        sortable: true,
        render: (row) => row.modelname,
      },
      {
        key: 'sizename',
        label: 'ไซส์',
        sortable: true,
        render: (row) => row.sizename,
      },
      {
        key: 'colorname',
        label: 'สี',
        sortable: true,
        render: (row) => row.colorname,
      },
      {
        key: 'sellprodprice',
        label: 'ราคา',
        sortable: true,
        render: (row) =>
          row.sellprodprice != null ? row.sellprodprice.toLocaleString() : '0',
        className: 'text-end',
      },
      {
        key: 'sellproddis',
        label: 'ลด',
        sortable: true,
        render: (row) =>
          row.sellproddis != null ? row.sellproddis.toLocaleString() : '0',
        className: 'text-end',
      },
      {
        key: 'sellprodqty',
        label: 'จำนวน',
        sortable: true,
        render: (row) =>
          row.sellprodqty != null ? row.sellprodqty.toLocaleString() : '0',
        className: 'text-center',
      },
      {
        key: 'sum',
        label: 'รวม',
        sortable: false,
        render: (row) => {
          const price = row.sellprodprice != null ? row.sellprodprice : 0;
          const discount = row.sellproddis != null ? row.sellproddis : 0;
          const qty = row.sellprodqty != null ? row.sellprodqty : 0;
          const total = (price - discount) * qty;
          return total.toLocaleString();
        },
        className: 'text-end',
      },
      {
        key: 'id',
        label: 'Sell Product ID',
        sortable: true,
        render: (row) => row.id,
        className: 'text-center',
      },
      {
        key: 'sellprodcancel',
        label: 'ยกเลิก',
        sortable: true,
        render: (row) => (row.sellprodcancel ? 'ใช่' : 'ไม่ใช่'),
      },
    ];
  
    // Initialize selected columns in Redux store if not already set
    useEffect(() => {
      if (selectedColumns.length === 0 && columns.length > 0) {
        // Set selected columns to show all columns by default if there are no selected columns in Redux
        const allColumnKeys = columns.map((col) => col.key);
        dispatch(setColumns({ page: 'sellProd', columns: allColumnKeys }));
      }
    }, [dispatch, selectedColumns, columns]);
  
    const handleColumnsChange = (newSelectedColumns) => {
      dispatch(setColumns({ page: 'sellProd', columns: newSelectedColumns }));
    };

      // Handle Edit click
    const handleEditClick = (sellProd) => {
      setSelectedSellProd(sellProd);
      setShowModal(true);
      setIsDeleteConfirmation(false); // Reset delete confirmation state
    };

    // Handle Delete action
    const onDelete = () => {
      setIsDeleteConfirmation(true);
    };

    // Confirm Delete action
    const onConfirmDelete = async () => {
      if (selectedSellProd) {
        try {
          // Update sellprodcancel to true for the selected product
          await axiosInstance.patch(`/Sellprodsql/${selectedSellProd.id}/`, {
            sellprodcancel: true,
          });

          // Refresh the sell products data
          fetchSellProds(sellBillId);

          // Notify parent about data change
          onDataChange();

          setShowModal(false);
          setIsDeleteConfirmation(false);
          alert('Sell product canceled successfully.');
        } catch (error) {
          console.error('Error canceling sell product:', error);
          alert('Error canceling sell product.');
        }
      }
    };

    const onCancelDeleteConfirmation = () => {
      setIsDeleteConfirmation(false);
    };

    return (
      <div className="container">

        <h5>รายการขายสินค้า</h5>
          <CustomTable
            data={sellProds}
            columns={columns}
            selectedColumns={selectedColumns}
            onColumnsChange={handleColumnsChange}
            rowsPerPage={10}
            rowIdKey="idprodsell" // Use the correct key for product ID
            storageKey="sellProdTableSortState"
            showEditColumn={true} // Enable the Edit column
            onEdit={handleEditClick} // Pass the edit handler
            selectable={false}
            footerData={footerData} // Pass the footer data
            tableClassName="sell-job-table" // Add this line
          />
          {/* DoWithLineModal for handling edit/delete actions */}
          <DoWithLineModal
            show={showModal}
            onHide={() => {
              setShowModal(false);
              setIsDeleteConfirmation(false);
            }}
            itemName={`${selectedSellProd ? selectedSellProd.categoryname : ''} ${selectedSellProd ? selectedSellProd.typename : ''} ${selectedSellProd ? selectedSellProd.brandname : ''} ${selectedSellProd ? selectedSellProd.modelname : ''} ${selectedSellProd ? selectedSellProd.sizename : ''} ${selectedSellProd ? selectedSellProd.colorname : ''}`}
            onEdit={() => {
              // Implement edit functionality if needed
            }}
            onDelete={onDelete}
            onConfirmDelete={onConfirmDelete}
            onCancelDeleteConfirmation={onCancelDeleteConfirmation}
            isDeleteConfirmation={isDeleteConfirmation}
            showCreateNew={false}
          />
    </div>
    );
  }
  
  export default SellProdViewTable;